import React from 'react'

function Footer() {
  return (
    <div className='footer flex justify-center items-start bg-[#121213] py-5 text-white text-left' style={{minHeight: '400px', width: '100%'}}>
      <section className="flex flex-col text-left mx-3" style={{width: '40%'}}>
        <div className='text-left w-full mb-3 py-3' style={{borderBottom: '1px solid white'}}>
          <p>About Us</p>
        </div>
        <div>
          <p>UniMAC-IFT, formerly known as NAFTI, is a  vibrant student body that aims at ensuring students' growth academically, socially, and financially. Akwaaba Film Challenge is one of the Avenues that unleashes the hidden potential of students. 
This platform is specifically designed for first-year students. </p>
        </div>
      </section>
      <section className="flex flex-col text-left mx-3" style={{width: '40%'}}>
        <div className='text-left w-full mb-3 py-3' style={{borderBottom: '1px solid white'}}>
          <p>Contact Us</p>
        </div>
        <div className="flex flex-col w-full items-start text-left justify-between h-40">
          <p className='text-left'>Accra, Ghana</p>
 
          <p className='text-left'>+233272858379/+2330557124409/+2330275793837</p>
          <p className='text-left'>unimaciftsrc@gmail.com</p>
        </div>
      </section>
    </div>
  )
}

export default Footer