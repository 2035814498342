import { Route, Routes } from 'react-router-dom';
// import './App.css';
import HomePage from './Pages/HomePage/HomePage';
import About from './Pages/About/About'
import Terms from './Pages/Terms/Terms'
import Awards from './Pages/AwardsPage/Awards'
import Header from './Common/Header/Header';
import Footer from './Common/Footer/Footer';
import Categories from './Pages/Categories/Categories';
import Nominee from './Pages/Nominee/Nominee';
import BarChart from './Pages/Results/Results';

function App() {

    return (
     <div className = "App h-screen container" style={{}}>
        <Header/>

        <div className = 'overflow-x-hidden overflex-y-auto m-0 w-full container' style={{background: 'white'}} >
            <Routes >
            <Route path = '/' element = { <HomePage/> }/>
            < Route path = '/about' element = { <About/> }/>
             <Route path = '/terms' element = { <Terms/> }  />

            <Route path = '/awards/:id'element = { <Awards/> } /> 
            <Route path = '/categories/:id' element = { <Categories/> }   /> 
            <Route path = '/nominee/:id'element = { <Nominee/> }/>
            <Route path = '/dfb75ea1-1e85-44e3-b5be-399589e2ec68' element = {<BarChart/>} /> 

            </Routes>
            < Footer/>
        </div>
        
    </div>
    );
}

export default App;