import React from 'react'

function About() {
  return (
    <div className='flex flex-wrap m-auto' style={{width: '80%'}}>
      <h1>About Us</h1>
      <br />
<p>UniMAC-IFT, formerly known as NAFTI, is a  vibrant student body that aims at ensuring students' growth academically, socially, and financially. Akwaaba Film Challenge is one of the Avenues that unleashes the hidden potential of students. 
This platform is specifically designed for first-year students.
</p>
      <ul>
        <li>.</li>
        <li>.</li>
        <li>.</li>
        <li>.</li>
        <li>.</li>
        <li>.</li>
        <li>.</li>
        <li>.</li>
        <li>.</li>
        <li>.</li>
        <li>.</li>
        <li>.</li>
        <li>.</li>
        <li>.</li>
        <li>.</li>
      </ul>
    </div>
  )
}

export default About